@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap");

/* Import for Beaufort for LOL font (fallback to system fonts if unavailable) */
@font-face {
  font-family: "Beaufort for LOL";
  src: local("Beaufort for LOL");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Beaufort for LOL";
  src: local("Beaufort for LOL Bold");
  font-weight: bold;
  font-style: normal;
}

html {
  height: 100%;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-family: "Beaufort for LOL", "Roboto", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-blue);
  color: var(--light-gold);
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Custom scrollbar for webkit browsers */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background: var(--dark-gold);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--gold);
}

/* Remove blue highlight on mobile touch */
* {
  -webkit-tap-highlight-color: transparent;
}
